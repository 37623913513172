body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-wrap {
  position: relative;
  width: 66.666%;
  left: 33%;
  height: 70vh /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 100%;
  height: 70vh;
}

.map-popup {
  width: 100%;
  height: 100%;
}

.maplibregl-marker {
  margin: 20px;
}

.sidebar {
  position: absolute;
  margin-top: 50px;
  width: 33.3333%;
  overflow-y: scroll;
  border: px solid rgba(0, 0, 0, 0.25);
  height: 70vh;
  z-index: 999;
}

.sidebar::-webkit-scrollbar {
  width: 12px;
}

.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}



.heading {
  background: #fff;
  border-bottom: 1px solid #eee;
  margin-top: 20px;
  padding: 0 10px;
}

.listings {
  max-height: 70vh;
  padding-bottom: 60px;
}

.listings .item {
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-decoration: none;
}

.listings .item:last-child {
  border-bottom: none;
}

.listings .item .title {
  display: block;
  color: #00853e;
  font-weight: 700;
}

.listings .item .title small {
  font-weight: 400;
}

.listings .item.active .title,
.listings .item .title:hover {
  color: #8cc63f;
}

.listings .item.active {
  background-color: #f8f8f8;
}